import React, { Component } from 'react';
import icon from './icon.png';
import './App.css';
import {Background} from "./Background";

class App extends Component {
  state = {
    loaded: false
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({loaded: true})
    }, 100);
  }

  render() {
    return (
      <div className={`App ${this.state.loaded ? 'loaded' : ''}`}>
        <Background/>
        <div className="info-text">created by <a href={'https://github.com/lucasritter'}>lucas ritter</a></div>
        <div className="content-container">
          <div className="icon-container">
            <img src={icon} />
          </div>
          <div className="slogan">the next generation of modding</div>
          <div className="social-container">
            <div><a className="social" href="https://github.com/rage-package-manager" target="_blank">github</a></div>
            <div><a className="social" href="https://github.com/rage-package-manager" target="_blank">patreon</a></div>
            <div><a className="social" href="https://discord.gg/qkyxZhv" target="_blank">discord</a></div>
          </div>
        </div>
        <div className="info-text">coming soon</div>
      </div>
    );
  }
}

export default App;
